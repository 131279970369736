<template>
  <div>
    <div
      class="modal fade"
      id="order-details-popup"
      tabindex="-1"
      role="dialog"
      aria-labelledby="address-popup-label"
      aria-hidden="true"
      ref="vuemodal"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="order-info">
            <span v-if="order_details!=''">
              <div v-if="order_details.data.order_id==orderid">
                <h2 class="section-heading font-bold">Order Details</h2>
                <div class="order-box" v-if="order_details!=''">
                  <div class="profile-id" v-if="order_details.data!=''">
                    <div class="media">
                      <div class="order-list-user">
                      <div class="ordered-profile mr-3">
                        <a href="javascript:void(0)" @click="goToStorePage(order_details.data.store.store_id)">
                          <img
                            :src="order_details.data.store.store_logo"
                            alt="..."
                            v-if="order_details.data.store.store_logo!=''"
                          >
                          <avatar
                            :fullname="order_details.data.store.store_name.split(' ')[0]"
                            :size="80"
                            v-if="order_details.data.store.store_logo==''"
                          ></avatar>
                        </a>
                      </div>
                    <a :href="'tel:'+order_details.data.store.phone" class="my-order-call-icon">
                      <img src="../../assets/images/svgs/call-green.svg">
                    </a>
                    </div>
                      <div class="media-body">
                        <div class="row form-row">
                          <div class="col-sm-7 order-description">
                            <a href="javascript:void(0)" @click="goToStorePage(order_details.data.store.store_id)" :title="order_details.data.store.store_name" style="color: #000;">
                              <h5>{{order_details.data.store.store_name}}</h5>
                            </a>
                            <!-- <a href="javascript:void(0)" @click="getStoreByCategory(order_details.data.store)" :title="order_details.data.store.category_name" style="display: inline;">
                              <h6>{{order_details.data.store.category_name}}</h6>
                            </a> -->
                            <a class="location" href="#">
                              <img
                                src="../../assets/images/location-icon-gray.png"
                                alt="location-icon.png"
                              >
                              <!-- <span v-if="order_details.data.store.area_name"> {{order_details.data.store.area_name}}</span> -->
                              <span v-if="order_details.data.store.city_name"> {{order_details.data.store.city_name}}</span>
                              <span v-if="order_details.data.store.state_name">, {{order_details.data.store.state_name}}</span>
                            </a>
                          </div>
                          <div
                            class="col-sm-5 delivery-description text-sm-right mt-3 mt-sm-0"
                            v-if="order_details.data.order_status!=''"
                          >
                            <h5 v-if="order_details.data.order_status==1">
                              Pending
                            </h5>
                            <div v-if="order_details.data.order_status==1" class="d-flex align-items-center justify-content-end">
                                <a href="javascript:void(0)" class="order-inquiry" @click="contact(order_details.data.order_no, order_details.data.order_date)" data-toggle="modal" data-target="#ContactUsModal">
                                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                      viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                    <g>
                                      <g>
                                        <circle class="st0" cx="50" cy="50" r="50"/>
                                      </g>
                                      <g>
                                        <path class="st1" d="M49.5,75c-0.9-0.2-1.8-0.5-2.5-1.2c-1.3-1.2-1.7-2.7-1.3-4.4c0.5-1.8,1.7-2.9,3.5-3.1
                                          c2.1-0.3,3.8,0.6,4.7,2.6c0.1,0.2,0.2,0.3,0.5,0.3c1.4,0,2.8,0,4.2,0c2,0,3.6-1.2,4.3-3.1c-0.8,0.2-1.6,0.3-2.4,0
                                          c-2-0.6-3.2-2.1-3.3-4.2c0-4-0.1-7.9,0-11.9c0.1-3.5,3.7-5.5,6.7-3.7c1.4,0.8,2.1,2.1,2.1,3.7c0,5,0.1,10.1,0,15.1
                                          c0,3.8-3.2,6.9-7,7c-1.5,0-3.1,0-4.6,0c-0.2,0-0.4,0-0.5,0.3c-0.7,1.4-1.8,2.3-3.4,2.6c0,0,0,0-0.1,0.1C50.2,75,49.9,75,49.5,75z"
                                          />
                                        <path class="st1" d="M28,47c0-12.2,10.1-22.4,22.7-22C63,25.4,72,35.6,71.9,47c-0.6-0.4-1.2-0.8-1.9-1c-0.1,0-0.2-0.1-0.2-0.1
                                          c-0.8,0-0.9-0.6-1-1.3c-0.4-3.4-1.6-6.4-3.6-9.1c-2-2.7-4.6-4.8-7.8-6.1c-2.6-1.1-5.3-1.6-8.2-1.5c-3.3,0.1-6.4,1.1-9.3,2.8
                                          c-3.3,2-5.7,4.8-7.3,8.3c-0.9,2-1.4,4.1-1.6,6.2c0,0.2,0,0.3-0.2,0.4C29.8,45.9,28.9,46.4,28,47z"/>
                                        <path class="st1" d="M33.9,55.8c0-1.9,0-3.8,0-5.8c0-2,1.2-3.7,2.9-4.3c2.9-0.9,5.8,1,5.8,4.1c0.1,4,0.1,8,0,12
                                          c-0.1,3.5-3.7,5.4-6.7,3.7c-1.5-0.9-2.1-2.2-2.1-3.9C33.9,59.7,33.9,57.8,33.9,55.8z"/>
                                        <path class="st1" d="M31,63c-1.5-0.6-2.5-1.7-2.9-3.3c-0.1-0.3-0.1-0.6-0.1-1c0-2,0-3.9,0-5.9c0-1.9,1.1-3.5,2.8-4.2
                                          c0.1,0,0.1,0,0.2,0C31,53.5,31,58.2,31,63z"/>
                                        <path class="st1" d="M69,63c0-4.8,0-9.5,0-14.3c1.5,0.4,2.9,2,2.9,3.7c0.1,2.3,0.1,4.6,0,6.8C71.8,61.1,70.7,62.3,69,63z"/>
                                      </g>
                                    </g>
                                  </svg>
                                </a>
                                <div class="message-row" v-if="is_chat_active == 1 && order_details.data.is_group_available == 1">
                                  <a href="javascript:void(0)" class="message-icons ml-auto" @click="orderChat(order_details.data.order_id)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.906" height="16" viewBox="0 0 21.906 16">
                                      <g id="message" transform="translate(-1974.185 -1096.78)">
                                        <path id="Path_41" data-name="Path 41" d="M1990.478,1108.245a5.734,5.734,0,0,0,0-11.465H1979.8a5.743,5.743,0,0,0-.872,11.4v3.73a.873.873,0,0,0,1.523.58l3.78-4.242Zm-.023-6.813a1.081,1.081,0,1,1-1.08,1.081A1.081,1.081,0,0,1,1990.455,1101.432Zm-10.635,2.161a1.081,1.081,0,1,1,1.081-1.08A1.08,1.08,0,0,1,1979.82,1103.593Zm5.318-2.161a1.081,1.081,0,1,1-1.081,1.081A1.081,1.081,0,0,1,1985.138,1101.432Z" fill="#fff"/>
                                      </g>
                                    </svg>
                                  </a>
                              </div>
                            </div>
                            <h5 v-if="order_details.data.order_status==2">
                              Accepted
                            </h5>
                            <div v-if="order_details.data.order_status==2" class="d-flex align-items-center mt-3 justify-content-end">
                                <a href="javascript:void(0)" class="order-inquiry" @click="contact(order_details.data.order_no, order_details.data.order_date)" data-toggle="modal" data-target="#ContactUsModal">
                                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                      viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                    <g>
                                      <g>
                                        <circle class="st0" cx="50" cy="50" r="50"/>
                                      </g>
                                      <g>
                                        <path class="st1" d="M49.5,75c-0.9-0.2-1.8-0.5-2.5-1.2c-1.3-1.2-1.7-2.7-1.3-4.4c0.5-1.8,1.7-2.9,3.5-3.1
                                          c2.1-0.3,3.8,0.6,4.7,2.6c0.1,0.2,0.2,0.3,0.5,0.3c1.4,0,2.8,0,4.2,0c2,0,3.6-1.2,4.3-3.1c-0.8,0.2-1.6,0.3-2.4,0
                                          c-2-0.6-3.2-2.1-3.3-4.2c0-4-0.1-7.9,0-11.9c0.1-3.5,3.7-5.5,6.7-3.7c1.4,0.8,2.1,2.1,2.1,3.7c0,5,0.1,10.1,0,15.1
                                          c0,3.8-3.2,6.9-7,7c-1.5,0-3.1,0-4.6,0c-0.2,0-0.4,0-0.5,0.3c-0.7,1.4-1.8,2.3-3.4,2.6c0,0,0,0-0.1,0.1C50.2,75,49.9,75,49.5,75z"
                                          />
                                        <path class="st1" d="M28,47c0-12.2,10.1-22.4,22.7-22C63,25.4,72,35.6,71.9,47c-0.6-0.4-1.2-0.8-1.9-1c-0.1,0-0.2-0.1-0.2-0.1
                                          c-0.8,0-0.9-0.6-1-1.3c-0.4-3.4-1.6-6.4-3.6-9.1c-2-2.7-4.6-4.8-7.8-6.1c-2.6-1.1-5.3-1.6-8.2-1.5c-3.3,0.1-6.4,1.1-9.3,2.8
                                          c-3.3,2-5.7,4.8-7.3,8.3c-0.9,2-1.4,4.1-1.6,6.2c0,0.2,0,0.3-0.2,0.4C29.8,45.9,28.9,46.4,28,47z"/>
                                        <path class="st1" d="M33.9,55.8c0-1.9,0-3.8,0-5.8c0-2,1.2-3.7,2.9-4.3c2.9-0.9,5.8,1,5.8,4.1c0.1,4,0.1,8,0,12
                                          c-0.1,3.5-3.7,5.4-6.7,3.7c-1.5-0.9-2.1-2.2-2.1-3.9C33.9,59.7,33.9,57.8,33.9,55.8z"/>
                                        <path class="st1" d="M31,63c-1.5-0.6-2.5-1.7-2.9-3.3c-0.1-0.3-0.1-0.6-0.1-1c0-2,0-3.9,0-5.9c0-1.9,1.1-3.5,2.8-4.2
                                          c0.1,0,0.1,0,0.2,0C31,53.5,31,58.2,31,63z"/>
                                        <path class="st1" d="M69,63c0-4.8,0-9.5,0-14.3c1.5,0.4,2.9,2,2.9,3.7c0.1,2.3,0.1,4.6,0,6.8C71.8,61.1,70.7,62.3,69,63z"/>
                                      </g>
                                    </g>
                                  </svg>
                                </a>
                                <div class="message-row" v-if="is_chat_active == 1">
                                  <router-link :to="{name: 'chat', params: {chatorderid:order_details.data.order_id}}" class="message-icons ml-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.906" height="16" viewBox="0 0 21.906 16">
                                      <g id="message" transform="translate(-1974.185 -1096.78)">
                                        <path id="Path_41" data-name="Path 41" d="M1990.478,1108.245a5.734,5.734,0,0,0,0-11.465H1979.8a5.743,5.743,0,0,0-.872,11.4v3.73a.873.873,0,0,0,1.523.58l3.78-4.242Zm-.023-6.813a1.081,1.081,0,1,1-1.08,1.081A1.081,1.081,0,0,1,1990.455,1101.432Zm-10.635,2.161a1.081,1.081,0,1,1,1.081-1.08A1.08,1.08,0,0,1,1979.82,1103.593Zm5.318-2.161a1.081,1.081,0,1,1-1.081,1.081A1.081,1.081,0,0,1,1985.138,1101.432Z" fill="#fff"/>
                                      </g>
                                    </svg>
                                  </router-link>
                                </div>
                              </div>
                            <h5 v-if="order_details.data.order_status==3">Ready For Pickup</h5>
                            <div class="d-flex align-items-center justify-content-end" v-if="order_details.data.order_status==3">
                                <a href="javascript:void(0)" class="order-inquiry" @click="contact(order_details.data.order_no, order_details.data.order_date)" data-toggle="modal" data-target="#ContactUsModal">
                                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                      viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                    <g>
                                      <g>
                                        <circle class="st0" cx="50" cy="50" r="50"/>
                                      </g>
                                      <g>
                                        <path class="st1" d="M49.5,75c-0.9-0.2-1.8-0.5-2.5-1.2c-1.3-1.2-1.7-2.7-1.3-4.4c0.5-1.8,1.7-2.9,3.5-3.1
                                          c2.1-0.3,3.8,0.6,4.7,2.6c0.1,0.2,0.2,0.3,0.5,0.3c1.4,0,2.8,0,4.2,0c2,0,3.6-1.2,4.3-3.1c-0.8,0.2-1.6,0.3-2.4,0
                                          c-2-0.6-3.2-2.1-3.3-4.2c0-4-0.1-7.9,0-11.9c0.1-3.5,3.7-5.5,6.7-3.7c1.4,0.8,2.1,2.1,2.1,3.7c0,5,0.1,10.1,0,15.1
                                          c0,3.8-3.2,6.9-7,7c-1.5,0-3.1,0-4.6,0c-0.2,0-0.4,0-0.5,0.3c-0.7,1.4-1.8,2.3-3.4,2.6c0,0,0,0-0.1,0.1C50.2,75,49.9,75,49.5,75z"
                                          />
                                        <path class="st1" d="M28,47c0-12.2,10.1-22.4,22.7-22C63,25.4,72,35.6,71.9,47c-0.6-0.4-1.2-0.8-1.9-1c-0.1,0-0.2-0.1-0.2-0.1
                                          c-0.8,0-0.9-0.6-1-1.3c-0.4-3.4-1.6-6.4-3.6-9.1c-2-2.7-4.6-4.8-7.8-6.1c-2.6-1.1-5.3-1.6-8.2-1.5c-3.3,0.1-6.4,1.1-9.3,2.8
                                          c-3.3,2-5.7,4.8-7.3,8.3c-0.9,2-1.4,4.1-1.6,6.2c0,0.2,0,0.3-0.2,0.4C29.8,45.9,28.9,46.4,28,47z"/>
                                        <path class="st1" d="M33.9,55.8c0-1.9,0-3.8,0-5.8c0-2,1.2-3.7,2.9-4.3c2.9-0.9,5.8,1,5.8,4.1c0.1,4,0.1,8,0,12
                                          c-0.1,3.5-3.7,5.4-6.7,3.7c-1.5-0.9-2.1-2.2-2.1-3.9C33.9,59.7,33.9,57.8,33.9,55.8z"/>
                                        <path class="st1" d="M31,63c-1.5-0.6-2.5-1.7-2.9-3.3c-0.1-0.3-0.1-0.6-0.1-1c0-2,0-3.9,0-5.9c0-1.9,1.1-3.5,2.8-4.2
                                          c0.1,0,0.1,0,0.2,0C31,53.5,31,58.2,31,63z"/>
                                        <path class="st1" d="M69,63c0-4.8,0-9.5,0-14.3c1.5,0.4,2.9,2,2.9,3.7c0.1,2.3,0.1,4.6,0,6.8C71.8,61.1,70.7,62.3,69,63z"/>
                                      </g>
                                    </g>
                                  </svg>
                                </a>
                                <div class="message-row" v-if="is_chat_active == 1">
                                  <router-link :to="{name: 'chat', params: {chatorderid:order_details.data.order_id}}" class="message-icons ml-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.906" height="16" viewBox="0 0 21.906 16">
                                      <g id="message" transform="translate(-1974.185 -1096.78)">
                                        <path id="Path_41" data-name="Path 41" d="M1990.478,1108.245a5.734,5.734,0,0,0,0-11.465H1979.8a5.743,5.743,0,0,0-.872,11.4v3.73a.873.873,0,0,0,1.523.58l3.78-4.242Zm-.023-6.813a1.081,1.081,0,1,1-1.08,1.081A1.081,1.081,0,0,1,1990.455,1101.432Zm-10.635,2.161a1.081,1.081,0,1,1,1.081-1.08A1.08,1.08,0,0,1,1979.82,1103.593Zm5.318-2.161a1.081,1.081,0,1,1-1.081,1.081A1.081,1.081,0,0,1,1985.138,1101.432Z" fill="#fff"/>
                                      </g>
                                    </svg>
                                  </router-link>
                                </div>
                              </div>
                            <h5
                              v-if="order_details.data.order_status==4"
                              class="delivery-label"
                            >Out for Delivery
                            <div class="d-flex align-items-center justify-content-end" v-if="order_details.data.order_status==4">
                                <a href="javascript:void(0)" class="order-inquiry" @click="contact(order_details.data.order_no, order_details.data.order_date)" data-toggle="modal" data-target="#ContactUsModal">
                                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                      viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                    <g>
                                      <g>
                                        <circle class="st0" cx="50" cy="50" r="50"/>
                                      </g>
                                      <g>
                                        <path class="st1" d="M49.5,75c-0.9-0.2-1.8-0.5-2.5-1.2c-1.3-1.2-1.7-2.7-1.3-4.4c0.5-1.8,1.7-2.9,3.5-3.1
                                          c2.1-0.3,3.8,0.6,4.7,2.6c0.1,0.2,0.2,0.3,0.5,0.3c1.4,0,2.8,0,4.2,0c2,0,3.6-1.2,4.3-3.1c-0.8,0.2-1.6,0.3-2.4,0
                                          c-2-0.6-3.2-2.1-3.3-4.2c0-4-0.1-7.9,0-11.9c0.1-3.5,3.7-5.5,6.7-3.7c1.4,0.8,2.1,2.1,2.1,3.7c0,5,0.1,10.1,0,15.1
                                          c0,3.8-3.2,6.9-7,7c-1.5,0-3.1,0-4.6,0c-0.2,0-0.4,0-0.5,0.3c-0.7,1.4-1.8,2.3-3.4,2.6c0,0,0,0-0.1,0.1C50.2,75,49.9,75,49.5,75z"
                                          />
                                        <path class="st1" d="M28,47c0-12.2,10.1-22.4,22.7-22C63,25.4,72,35.6,71.9,47c-0.6-0.4-1.2-0.8-1.9-1c-0.1,0-0.2-0.1-0.2-0.1
                                          c-0.8,0-0.9-0.6-1-1.3c-0.4-3.4-1.6-6.4-3.6-9.1c-2-2.7-4.6-4.8-7.8-6.1c-2.6-1.1-5.3-1.6-8.2-1.5c-3.3,0.1-6.4,1.1-9.3,2.8
                                          c-3.3,2-5.7,4.8-7.3,8.3c-0.9,2-1.4,4.1-1.6,6.2c0,0.2,0,0.3-0.2,0.4C29.8,45.9,28.9,46.4,28,47z"/>
                                        <path class="st1" d="M33.9,55.8c0-1.9,0-3.8,0-5.8c0-2,1.2-3.7,2.9-4.3c2.9-0.9,5.8,1,5.8,4.1c0.1,4,0.1,8,0,12
                                          c-0.1,3.5-3.7,5.4-6.7,3.7c-1.5-0.9-2.1-2.2-2.1-3.9C33.9,59.7,33.9,57.8,33.9,55.8z"/>
                                        <path class="st1" d="M31,63c-1.5-0.6-2.5-1.7-2.9-3.3c-0.1-0.3-0.1-0.6-0.1-1c0-2,0-3.9,0-5.9c0-1.9,1.1-3.5,2.8-4.2
                                          c0.1,0,0.1,0,0.2,0C31,53.5,31,58.2,31,63z"/>
                                        <path class="st1" d="M69,63c0-4.8,0-9.5,0-14.3c1.5,0.4,2.9,2,2.9,3.7c0.1,2.3,0.1,4.6,0,6.8C71.8,61.1,70.7,62.3,69,63z"/>
                                      </g>
                                    </g>
                                  </svg>
                                </a>
                                <div class="message-row" v-if="is_chat_active == 1">
                                  <router-link :to="{name: 'chat', params: {chatorderid:order_details.data.order_id}}" class="message-icons ml-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.906" height="16" viewBox="0 0 21.906 16">
                                      <g id="message" transform="translate(-1974.185 -1096.78)">
                                        <path id="Path_41" data-name="Path 41" d="M1990.478,1108.245a5.734,5.734,0,0,0,0-11.465H1979.8a5.743,5.743,0,0,0-.872,11.4v3.73a.873.873,0,0,0,1.523.58l3.78-4.242Zm-.023-6.813a1.081,1.081,0,1,1-1.08,1.081A1.081,1.081,0,0,1,1990.455,1101.432Zm-10.635,2.161a1.081,1.081,0,1,1,1.081-1.08A1.08,1.08,0,0,1,1979.82,1103.593Zm5.318-2.161a1.081,1.081,0,1,1-1.081,1.081A1.081,1.081,0,0,1,1985.138,1101.432Z" fill="#fff"/>
                                      </g>
                                    </svg>
                                  </router-link>
                              </div>
                            </div>
                            
                            </h5>
                            <h5 v-if="order_details.data.order_status==5">Delivered</h5>
                            <div v-if="order_details.data.order_status==5" class="d-flex align-items-center justify-content-end">
                                <a href="javascript:void(0)" class="order-inquiry" @click="contact(order_details.data.order_no, order_details.data.order_date)" data-toggle="modal" data-target="#ContactUsModal">
                                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                      viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                    <g>
                                      <g>
                                        <circle class="st0" cx="50" cy="50" r="50"/>
                                      </g>
                                      <g>
                                        <path class="st1" d="M49.5,75c-0.9-0.2-1.8-0.5-2.5-1.2c-1.3-1.2-1.7-2.7-1.3-4.4c0.5-1.8,1.7-2.9,3.5-3.1
                                          c2.1-0.3,3.8,0.6,4.7,2.6c0.1,0.2,0.2,0.3,0.5,0.3c1.4,0,2.8,0,4.2,0c2,0,3.6-1.2,4.3-3.1c-0.8,0.2-1.6,0.3-2.4,0
                                          c-2-0.6-3.2-2.1-3.3-4.2c0-4-0.1-7.9,0-11.9c0.1-3.5,3.7-5.5,6.7-3.7c1.4,0.8,2.1,2.1,2.1,3.7c0,5,0.1,10.1,0,15.1
                                          c0,3.8-3.2,6.9-7,7c-1.5,0-3.1,0-4.6,0c-0.2,0-0.4,0-0.5,0.3c-0.7,1.4-1.8,2.3-3.4,2.6c0,0,0,0-0.1,0.1C50.2,75,49.9,75,49.5,75z"
                                          />
                                        <path class="st1" d="M28,47c0-12.2,10.1-22.4,22.7-22C63,25.4,72,35.6,71.9,47c-0.6-0.4-1.2-0.8-1.9-1c-0.1,0-0.2-0.1-0.2-0.1
                                          c-0.8,0-0.9-0.6-1-1.3c-0.4-3.4-1.6-6.4-3.6-9.1c-2-2.7-4.6-4.8-7.8-6.1c-2.6-1.1-5.3-1.6-8.2-1.5c-3.3,0.1-6.4,1.1-9.3,2.8
                                          c-3.3,2-5.7,4.8-7.3,8.3c-0.9,2-1.4,4.1-1.6,6.2c0,0.2,0,0.3-0.2,0.4C29.8,45.9,28.9,46.4,28,47z"/>
                                        <path class="st1" d="M33.9,55.8c0-1.9,0-3.8,0-5.8c0-2,1.2-3.7,2.9-4.3c2.9-0.9,5.8,1,5.8,4.1c0.1,4,0.1,8,0,12
                                          c-0.1,3.5-3.7,5.4-6.7,3.7c-1.5-0.9-2.1-2.2-2.1-3.9C33.9,59.7,33.9,57.8,33.9,55.8z"/>
                                        <path class="st1" d="M31,63c-1.5-0.6-2.5-1.7-2.9-3.3c-0.1-0.3-0.1-0.6-0.1-1c0-2,0-3.9,0-5.9c0-1.9,1.1-3.5,2.8-4.2
                                          c0.1,0,0.1,0,0.2,0C31,53.5,31,58.2,31,63z"/>
                                        <path class="st1" d="M69,63c0-4.8,0-9.5,0-14.3c1.5,0.4,2.9,2,2.9,3.7c0.1,2.3,0.1,4.6,0,6.8C71.8,61.1,70.7,62.3,69,63z"/>
                                      </g>
                                    </g>
                                  </svg>
                                </a>
                                <div class="message-row" v-if="is_chat_active == 1">
                                  <router-link :to="{name: 'chat', params: {chatorderid:order_details.data.order_id}}" class="message-icons ml-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.906" height="16" viewBox="0 0 21.906 16">
                                      <g id="message" transform="translate(-1974.185 -1096.78)">
                                        <path id="Path_41" data-name="Path 41" d="M1990.478,1108.245a5.734,5.734,0,0,0,0-11.465H1979.8a5.743,5.743,0,0,0-.872,11.4v3.73a.873.873,0,0,0,1.523.58l3.78-4.242Zm-.023-6.813a1.081,1.081,0,1,1-1.08,1.081A1.081,1.081,0,0,1,1990.455,1101.432Zm-10.635,2.161a1.081,1.081,0,1,1,1.081-1.08A1.08,1.08,0,0,1,1979.82,1103.593Zm5.318-2.161a1.081,1.081,0,1,1-1.081,1.081A1.081,1.081,0,0,1,1985.138,1101.432Z" fill="#fff"/>
                                      </g>
                                    </svg>
                                  </router-link>
                                </div>
                              </div>
                            <h5 v-if="order_details.data.order_status==6">Order Rejected</h5>
                            <div v-if="order_details.data.order_status==6" class="d-flex align-items-center justify-content-end">
                                <a href="javascript:void(0)" class="order-inquiry" @click="contact(order_details.data.order_no, order_details.data.order_date)" data-toggle="modal" data-target="#ContactUsModal">
                                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                      viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                    <g>
                                      <g>
                                        <circle class="st0" cx="50" cy="50" r="50"/>
                                      </g>
                                      <g>
                                        <path class="st1" d="M49.5,75c-0.9-0.2-1.8-0.5-2.5-1.2c-1.3-1.2-1.7-2.7-1.3-4.4c0.5-1.8,1.7-2.9,3.5-3.1
                                          c2.1-0.3,3.8,0.6,4.7,2.6c0.1,0.2,0.2,0.3,0.5,0.3c1.4,0,2.8,0,4.2,0c2,0,3.6-1.2,4.3-3.1c-0.8,0.2-1.6,0.3-2.4,0
                                          c-2-0.6-3.2-2.1-3.3-4.2c0-4-0.1-7.9,0-11.9c0.1-3.5,3.7-5.5,6.7-3.7c1.4,0.8,2.1,2.1,2.1,3.7c0,5,0.1,10.1,0,15.1
                                          c0,3.8-3.2,6.9-7,7c-1.5,0-3.1,0-4.6,0c-0.2,0-0.4,0-0.5,0.3c-0.7,1.4-1.8,2.3-3.4,2.6c0,0,0,0-0.1,0.1C50.2,75,49.9,75,49.5,75z"
                                          />
                                        <path class="st1" d="M28,47c0-12.2,10.1-22.4,22.7-22C63,25.4,72,35.6,71.9,47c-0.6-0.4-1.2-0.8-1.9-1c-0.1,0-0.2-0.1-0.2-0.1
                                          c-0.8,0-0.9-0.6-1-1.3c-0.4-3.4-1.6-6.4-3.6-9.1c-2-2.7-4.6-4.8-7.8-6.1c-2.6-1.1-5.3-1.6-8.2-1.5c-3.3,0.1-6.4,1.1-9.3,2.8
                                          c-3.3,2-5.7,4.8-7.3,8.3c-0.9,2-1.4,4.1-1.6,6.2c0,0.2,0,0.3-0.2,0.4C29.8,45.9,28.9,46.4,28,47z"/>
                                        <path class="st1" d="M33.9,55.8c0-1.9,0-3.8,0-5.8c0-2,1.2-3.7,2.9-4.3c2.9-0.9,5.8,1,5.8,4.1c0.1,4,0.1,8,0,12
                                          c-0.1,3.5-3.7,5.4-6.7,3.7c-1.5-0.9-2.1-2.2-2.1-3.9C33.9,59.7,33.9,57.8,33.9,55.8z"/>
                                        <path class="st1" d="M31,63c-1.5-0.6-2.5-1.7-2.9-3.3c-0.1-0.3-0.1-0.6-0.1-1c0-2,0-3.9,0-5.9c0-1.9,1.1-3.5,2.8-4.2
                                          c0.1,0,0.1,0,0.2,0C31,53.5,31,58.2,31,63z"/>
                                        <path class="st1" d="M69,63c0-4.8,0-9.5,0-14.3c1.5,0.4,2.9,2,2.9,3.7c0.1,2.3,0.1,4.6,0,6.8C71.8,61.1,70.7,62.3,69,63z"/>
                                      </g>
                                    </g>
                                  </svg>
                                </a>
                                <div class="message-row" v-if="is_chat_active == 1">
                                  <router-link :to="{name: 'chat', params: {chatorderid:order_details.data.order_id}}" class="message-icons ml-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.906" height="16" viewBox="0 0 21.906 16">
                                      <g id="message" transform="translate(-1974.185 -1096.78)">
                                        <path id="Path_41" data-name="Path 41" d="M1990.478,1108.245a5.734,5.734,0,0,0,0-11.465H1979.8a5.743,5.743,0,0,0-.872,11.4v3.73a.873.873,0,0,0,1.523.58l3.78-4.242Zm-.023-6.813a1.081,1.081,0,1,1-1.08,1.081A1.081,1.081,0,0,1,1990.455,1101.432Zm-10.635,2.161a1.081,1.081,0,1,1,1.081-1.08A1.08,1.08,0,0,1,1979.82,1103.593Zm5.318-2.161a1.081,1.081,0,1,1-1.081,1.081A1.081,1.081,0,0,1,1985.138,1101.432Z" fill="#fff"/>
                                      </g>
                                    </svg>
                                  </router-link>
                                </div>
                              </div>
                            <h5 v-if="order_details.data.order_status==7">Un Delivered</h5>
                            <div v-if="order_details.data.order_status==7" class="d-flex align-items-center justify-content-end">
                                <a href="javascript:void(0)" class="order-inquiry" @click="contact(order_details.data.order_no, order_details.data.order_date)" data-toggle="modal" data-target="#ContactUsModal">
                                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                      viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                    <g>
                                      <g>
                                        <circle class="st0" cx="50" cy="50" r="50"/>
                                      </g>
                                      <g>
                                        <path class="st1" d="M49.5,75c-0.9-0.2-1.8-0.5-2.5-1.2c-1.3-1.2-1.7-2.7-1.3-4.4c0.5-1.8,1.7-2.9,3.5-3.1
                                          c2.1-0.3,3.8,0.6,4.7,2.6c0.1,0.2,0.2,0.3,0.5,0.3c1.4,0,2.8,0,4.2,0c2,0,3.6-1.2,4.3-3.1c-0.8,0.2-1.6,0.3-2.4,0
                                          c-2-0.6-3.2-2.1-3.3-4.2c0-4-0.1-7.9,0-11.9c0.1-3.5,3.7-5.5,6.7-3.7c1.4,0.8,2.1,2.1,2.1,3.7c0,5,0.1,10.1,0,15.1
                                          c0,3.8-3.2,6.9-7,7c-1.5,0-3.1,0-4.6,0c-0.2,0-0.4,0-0.5,0.3c-0.7,1.4-1.8,2.3-3.4,2.6c0,0,0,0-0.1,0.1C50.2,75,49.9,75,49.5,75z"
                                          />
                                        <path class="st1" d="M28,47c0-12.2,10.1-22.4,22.7-22C63,25.4,72,35.6,71.9,47c-0.6-0.4-1.2-0.8-1.9-1c-0.1,0-0.2-0.1-0.2-0.1
                                          c-0.8,0-0.9-0.6-1-1.3c-0.4-3.4-1.6-6.4-3.6-9.1c-2-2.7-4.6-4.8-7.8-6.1c-2.6-1.1-5.3-1.6-8.2-1.5c-3.3,0.1-6.4,1.1-9.3,2.8
                                          c-3.3,2-5.7,4.8-7.3,8.3c-0.9,2-1.4,4.1-1.6,6.2c0,0.2,0,0.3-0.2,0.4C29.8,45.9,28.9,46.4,28,47z"/>
                                        <path class="st1" d="M33.9,55.8c0-1.9,0-3.8,0-5.8c0-2,1.2-3.7,2.9-4.3c2.9-0.9,5.8,1,5.8,4.1c0.1,4,0.1,8,0,12
                                          c-0.1,3.5-3.7,5.4-6.7,3.7c-1.5-0.9-2.1-2.2-2.1-3.9C33.9,59.7,33.9,57.8,33.9,55.8z"/>
                                        <path class="st1" d="M31,63c-1.5-0.6-2.5-1.7-2.9-3.3c-0.1-0.3-0.1-0.6-0.1-1c0-2,0-3.9,0-5.9c0-1.9,1.1-3.5,2.8-4.2
                                          c0.1,0,0.1,0,0.2,0C31,53.5,31,58.2,31,63z"/>
                                        <path class="st1" d="M69,63c0-4.8,0-9.5,0-14.3c1.5,0.4,2.9,2,2.9,3.7c0.1,2.3,0.1,4.6,0,6.8C71.8,61.1,70.7,62.3,69,63z"/>
                                      </g>
                                    </g>
                                  </svg>
                                </a>
                                <div class="message-row" v-if="is_chat_active == 1">
                                  <router-link :to="{name: 'chat', params: {chatorderid:order_details.data.order_id}}" class="message-icons ml-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.906" height="16" viewBox="0 0 21.906 16">
                                      <g id="message" transform="translate(-1974.185 -1096.78)">
                                        <path id="Path_41" data-name="Path 41" d="M1990.478,1108.245a5.734,5.734,0,0,0,0-11.465H1979.8a5.743,5.743,0,0,0-.872,11.4v3.73a.873.873,0,0,0,1.523.58l3.78-4.242Zm-.023-6.813a1.081,1.081,0,1,1-1.08,1.081A1.081,1.081,0,0,1,1990.455,1101.432Zm-10.635,2.161a1.081,1.081,0,1,1,1.081-1.08A1.08,1.08,0,0,1,1979.82,1103.593Zm5.318-2.161a1.081,1.081,0,1,1-1.081,1.081A1.081,1.081,0,0,1,1985.138,1101.432Z" fill="#fff"/>
                                      </g>
                                    </svg>
                                  </router-link>
                                </div>
                              </div>
                            <h5 v-if="order_details.data.order_status==8">Cancelled</h5>
                            <div v-if="order_details.data.order_status==8" class="d-flex align-items-center justify-content-end">
                                <a href="javascript:void(0)" class="order-inquiry" @click="contact(order_details.data.order_no, order_details.data.order_date)" data-toggle="modal" data-target="#ContactUsModal">
                                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                      viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                    <g>
                                      <g>
                                        <circle class="st0" cx="50" cy="50" r="50"/>
                                      </g>
                                      <g>
                                        <path class="st1" d="M49.5,75c-0.9-0.2-1.8-0.5-2.5-1.2c-1.3-1.2-1.7-2.7-1.3-4.4c0.5-1.8,1.7-2.9,3.5-3.1
                                          c2.1-0.3,3.8,0.6,4.7,2.6c0.1,0.2,0.2,0.3,0.5,0.3c1.4,0,2.8,0,4.2,0c2,0,3.6-1.2,4.3-3.1c-0.8,0.2-1.6,0.3-2.4,0
                                          c-2-0.6-3.2-2.1-3.3-4.2c0-4-0.1-7.9,0-11.9c0.1-3.5,3.7-5.5,6.7-3.7c1.4,0.8,2.1,2.1,2.1,3.7c0,5,0.1,10.1,0,15.1
                                          c0,3.8-3.2,6.9-7,7c-1.5,0-3.1,0-4.6,0c-0.2,0-0.4,0-0.5,0.3c-0.7,1.4-1.8,2.3-3.4,2.6c0,0,0,0-0.1,0.1C50.2,75,49.9,75,49.5,75z"
                                          />
                                        <path class="st1" d="M28,47c0-12.2,10.1-22.4,22.7-22C63,25.4,72,35.6,71.9,47c-0.6-0.4-1.2-0.8-1.9-1c-0.1,0-0.2-0.1-0.2-0.1
                                          c-0.8,0-0.9-0.6-1-1.3c-0.4-3.4-1.6-6.4-3.6-9.1c-2-2.7-4.6-4.8-7.8-6.1c-2.6-1.1-5.3-1.6-8.2-1.5c-3.3,0.1-6.4,1.1-9.3,2.8
                                          c-3.3,2-5.7,4.8-7.3,8.3c-0.9,2-1.4,4.1-1.6,6.2c0,0.2,0,0.3-0.2,0.4C29.8,45.9,28.9,46.4,28,47z"/>
                                        <path class="st1" d="M33.9,55.8c0-1.9,0-3.8,0-5.8c0-2,1.2-3.7,2.9-4.3c2.9-0.9,5.8,1,5.8,4.1c0.1,4,0.1,8,0,12
                                          c-0.1,3.5-3.7,5.4-6.7,3.7c-1.5-0.9-2.1-2.2-2.1-3.9C33.9,59.7,33.9,57.8,33.9,55.8z"/>
                                        <path class="st1" d="M31,63c-1.5-0.6-2.5-1.7-2.9-3.3c-0.1-0.3-0.1-0.6-0.1-1c0-2,0-3.9,0-5.9c0-1.9,1.1-3.5,2.8-4.2
                                          c0.1,0,0.1,0,0.2,0C31,53.5,31,58.2,31,63z"/>
                                        <path class="st1" d="M69,63c0-4.8,0-9.5,0-14.3c1.5,0.4,2.9,2,2.9,3.7c0.1,2.3,0.1,4.6,0,6.8C71.8,61.1,70.7,62.3,69,63z"/>
                                      </g>
                                    </g>
                                  </svg>
                                </a>
                                <div class="message-row" v-if="is_chat_active == 1">
                                  <router-link :to="{name: 'chat', params: {chatorderid:order_details.data.order_id}}" class="message-icons ml-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.906" height="16" viewBox="0 0 21.906 16">
                                      <g id="message" transform="translate(-1974.185 -1096.78)">
                                        <path id="Path_41" data-name="Path 41" d="M1990.478,1108.245a5.734,5.734,0,0,0,0-11.465H1979.8a5.743,5.743,0,0,0-.872,11.4v3.73a.873.873,0,0,0,1.523.58l3.78-4.242Zm-.023-6.813a1.081,1.081,0,1,1-1.08,1.081A1.081,1.081,0,0,1,1990.455,1101.432Zm-10.635,2.161a1.081,1.081,0,1,1,1.081-1.08A1.08,1.08,0,0,1,1979.82,1103.593Zm5.318-2.161a1.081,1.081,0,1,1-1.081,1.081A1.081,1.081,0,0,1,1985.138,1101.432Z" fill="#fff"/>
                                      </g>
                                    </svg>
                                  </router-link>
                                </div>
                            </div>
                            <h5 v-if="order_details.data.order_status==9">In-Process</h5>
                            <div v-if="order_details.data.order_status==9" class="d-flex align-items-center justify-content-end">
                                <a href="javascript:void(0)" class="order-inquiry" @click="contact(order_details.data.order_no, order_details.data.order_date)" data-toggle="modal" data-target="#ContactUsModal">
                                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                      viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
                                    <g>
                                      <g>
                                        <circle class="st0" cx="50" cy="50" r="50"/>
                                      </g>
                                      <g>
                                        <path class="st1" d="M49.5,75c-0.9-0.2-1.8-0.5-2.5-1.2c-1.3-1.2-1.7-2.7-1.3-4.4c0.5-1.8,1.7-2.9,3.5-3.1
                                          c2.1-0.3,3.8,0.6,4.7,2.6c0.1,0.2,0.2,0.3,0.5,0.3c1.4,0,2.8,0,4.2,0c2,0,3.6-1.2,4.3-3.1c-0.8,0.2-1.6,0.3-2.4,0
                                          c-2-0.6-3.2-2.1-3.3-4.2c0-4-0.1-7.9,0-11.9c0.1-3.5,3.7-5.5,6.7-3.7c1.4,0.8,2.1,2.1,2.1,3.7c0,5,0.1,10.1,0,15.1
                                          c0,3.8-3.2,6.9-7,7c-1.5,0-3.1,0-4.6,0c-0.2,0-0.4,0-0.5,0.3c-0.7,1.4-1.8,2.3-3.4,2.6c0,0,0,0-0.1,0.1C50.2,75,49.9,75,49.5,75z"
                                          />
                                        <path class="st1" d="M28,47c0-12.2,10.1-22.4,22.7-22C63,25.4,72,35.6,71.9,47c-0.6-0.4-1.2-0.8-1.9-1c-0.1,0-0.2-0.1-0.2-0.1
                                          c-0.8,0-0.9-0.6-1-1.3c-0.4-3.4-1.6-6.4-3.6-9.1c-2-2.7-4.6-4.8-7.8-6.1c-2.6-1.1-5.3-1.6-8.2-1.5c-3.3,0.1-6.4,1.1-9.3,2.8
                                          c-3.3,2-5.7,4.8-7.3,8.3c-0.9,2-1.4,4.1-1.6,6.2c0,0.2,0,0.3-0.2,0.4C29.8,45.9,28.9,46.4,28,47z"/>
                                        <path class="st1" d="M33.9,55.8c0-1.9,0-3.8,0-5.8c0-2,1.2-3.7,2.9-4.3c2.9-0.9,5.8,1,5.8,4.1c0.1,4,0.1,8,0,12
                                          c-0.1,3.5-3.7,5.4-6.7,3.7c-1.5-0.9-2.1-2.2-2.1-3.9C33.9,59.7,33.9,57.8,33.9,55.8z"/>
                                        <path class="st1" d="M31,63c-1.5-0.6-2.5-1.7-2.9-3.3c-0.1-0.3-0.1-0.6-0.1-1c0-2,0-3.9,0-5.9c0-1.9,1.1-3.5,2.8-4.2
                                          c0.1,0,0.1,0,0.2,0C31,53.5,31,58.2,31,63z"/>
                                        <path class="st1" d="M69,63c0-4.8,0-9.5,0-14.3c1.5,0.4,2.9,2,2.9,3.7c0.1,2.3,0.1,4.6,0,6.8C71.8,61.1,70.7,62.3,69,63z"/>
                                      </g>
                                    </g>
                                  </svg>
                                </a>
                                <div class="message-row" v-if="is_chat_active == 1">
                                  <router-link :to="{name: 'chat', params: {chatorderid:order_details.data.order_id}}" class="message-icons ml-auto">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.906" height="16" viewBox="0 0 21.906 16">
                                      <g id="message" transform="translate(-1974.185 -1096.78)">
                                        <path id="Path_41" data-name="Path 41" d="M1990.478,1108.245a5.734,5.734,0,0,0,0-11.465H1979.8a5.743,5.743,0,0,0-.872,11.4v3.73a.873.873,0,0,0,1.523.58l3.78-4.242Zm-.023-6.813a1.081,1.081,0,1,1-1.08,1.081A1.081,1.081,0,0,1,1990.455,1101.432Zm-10.635,2.161a1.081,1.081,0,1,1,1.081-1.08A1.08,1.08,0,0,1,1979.82,1103.593Zm5.318-2.161a1.081,1.081,0,1,1-1.081,1.081A1.081,1.081,0,0,1,1985.138,1101.432Z" fill="#fff"/>
                                      </g>
                                    </svg>
                                  </router-link>
                                </div>
                            </div>
                            {{order_details.data.order}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion" id="accordionExample">
                  <div class="card" v-if="order_details.data.tracking_details != null">
                      <div class="your-order card-header" id="headingOne">
                        <h2 class="section-heading mt-0">
                            <button class="btn btn-link collapsed"  type="OrderTracking" data-toggle="collapse" data-target="#OrderTracking" aria-expanded="true" aria-controls="collapseOne"> Order Tracking 
                            <span class="icon">
                              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> </svg>
                            </span>
                          </button>
                        </h2>      
                      </div>
                        <div class="collapse" id="OrderTracking" aria-labelledby="headingOne" data-parent="#accordionExample"> 
                        <div class="card-body">
                         <span class="takeAway" v-if="order_details.data.delivery_type == 2 && order_details.data.order_address != '' && order_details.data.order_status != 5 && order_details.data.order_status != 6 && order_details.data.order_status != 8"><img src="../../assets/images/pickup_icon.png" alt="location"><span v-if="order_details.data.iseatery == 1"> Take Away </span><span v-if="order_details.data.iseatery != 1"> Pickup </span><br /> 
                         <div class="row takeAway-row">
                         <span class="col-lg-11 col-md-11">
                         {{order_details.data.order_address}}
                         </span>
                         <div class="send-location col-lg-1 col-md-1">
                         <a :href="store_map_link" class="btn" target="_blank"><img src="../../assets/images/ic_target.jpg" alt="location"></a>
                         </div>
                         </div>
                         
                         </span>
                        <div class="tracking-details" v-show="livetrackingenable==1 && latitude != null  && longitude != null && order_details.data.order_status != 5 && order_details.data.order_status != 6 && order_details.data.order_status !=8 && use_google_map == 1">
                          <GmapMap
                          :center="{lat:this.latitude, lng:this.longitude}"
                          map-type-id="roadmap"
                          :zoom="12"
                          class="mapSignUp"
                          ref="mmm"
                          >
                          
                          <gmap-polyline v-bind:path.sync="path" v-bind:options="{ strokeColor:'#008000', icons: [{icon: {path: 'M 0,-1 0,1',strokeOpacity: 1,scale: 4},offset: '0',repeat: '20px'}]}">
                          </gmap-polyline>
                          
                          <GmapMarker
                          :key="'m'+index"
                          :v-if="markers.length > 0"     
                          v-for="(m, index) in markers"                    
                          :position="m.position"
                          :clickable="false"
                          :draggable="false"
                          />

                          <GmapMarker
                          :key="'fm'+index"
                          :v-if="from_markers.length > 0 && order_details.data.delivery_type == 1"     
                          v-for="(fm, index) in from_markers"                    
                          :position="fm.position"
                          :clickable="false"
                          :draggable="false"
                          />
                          
                          </GmapMap>
                          <!-- :icon="{ url: require('../../assets/images/svgs/delievery.svg')}" -->
                          <a :href="customerappshorturl" target="_blank" v-if="(order_details.data.order_status == 1 || order_details.data.order_status == 2 || order_details.data.order_status == 4) && order_details.data.delivery_type == 1">
                            <center>
                              <img src="../../assets/images/scooter.png" class="deliveryboy"/><br />
                              <span>Download the app to view live tracking on app</span>
                            </center>
                          </a>
                        </div>
                        <div class="pod-details">
                          <h3 class="section-heading" v-if="(order_details.data.pod_images != null && order_details.data.pod_images.length > 0)">Proof Of Delivery</h3>
                          <ul class="details-listing list-unstyled mt-md-1 mb-md-0">
                          <section class="advertising py-0" v-if="order_details.data.pod_images != null && order_details.data.pod_images.length > 0">
                            <b-carousel
                              id="carousel-1"
                              :interval="5000"
                              controls
                              indicators
                            >
                              <b-carousel-slide v-for="(banner,index) in order_details.data.pod_images" v-bind:key="'banner'+index">
                                <template #img>
                                  <img
                                    class="d-block img-fluid w-100"
                                    :src="banner.pod_image"
                                    @click="bannerRedirect(banner)"
                                    v-bind:style="bannerBgImage(banner)"
                                  >
                                </template>
                              </b-carousel-slide>
                            </b-carousel>
                            </section>

                            <li v-if="order_details.data.pod_delivery_location != null && order_details.data.pod_delivery_location != undefined && order_details.data.pod_delivery_location.trim() != ''">
                              <h5>Delivery</h5>
                              <h6 class="mb-0">
                                {{ order_details.data.pod_delivery_location}}
                              </h6>
                            </li>

                            <li v-if="order_details.data.pod_notes != null && order_details.data.pod_notes != undefined && order_details.data.pod_notes.trim() != ''">
                              <h5>Delivery Note</h5>
                              <h6 class="mb-0">
                                {{ order_details.data.pod_notes}}
                              </h6>
                            </li>
                          </ul>
                        </div>
                        
                          <div class="media my-profile order-profile align-items-center" v-if="order_details.data.deliveryboy_id!=0 && (order_details.data.order_status == 4 || order_details.data.order_status == 5)" style="margin-top: 5px;">
                            <div class="profile-pic mr-3">
                              <img v-if="order_details.data.deliveryboy_picture != null" :src="order_details.data.deliveryboy_picture" :alt="order_details.data.deliveryboy_name" />
                              <avatar v-else :fullname="order_details.data.deliveryboy_name" :size="50"></avatar>
                            </div>
                            <div class="media-body" v-if="order_details.data.order_status == 4">
                              <p class="order-status">{{delivery_boy}} Assigned<br><b>{{order_details.data.deliveryboy_name}}</b></p>
                            </div>
                            <div class="media-body" v-if="order_details.data.order_status == 5">
                              <p class="order-status">Your order has been successfully delivered by {{order_details.data.deliveryboy_name}}</p>
                            </div>
                            <!-- <a class="call" v-if="order_details.data.order_status == 4" :href="'tel:'+order_details.data.deliveryboy_phone"> -->
                              <!-- <img src="../../assets/images/svgs/call.svg" alt="call"> -->
                            <!-- </a> -->
                          </div>
                          <div class="order-track">
                            <div  v-for="(tracking_details, index) in order_details.data.tracking_details" v-bind:key="'tracking_details'+index" :class="(tracking_details.status == 1) ? 'order-track-step active' : 'order-track-step'">
                              <div class="order-track-status">
                                <span class="order-track-status-dot">
                                  <span class="icon">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2" fill="currentColor" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/> </svg>
                                  </span>
                                </span>
                                <span class="order-track-status-line"></span>
                              </div>
                              <div class="order-track-text">
                                <p class="order-track-text-stat">{{tracking_details.title}}</p>
                                <span class="order-track-text-sub">{{tracking_details.datetime}}</span>
                                <p class="description">{{tracking_details.description}}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="card" v-if="order_details.data.order_review != null && order_details.data.order_status == 5 && (order_details.data.order_review.delivery_review != 1 || order_details.data.order_review.store_review != 1 || order_details.data.order_review.review_items != null) && review_rating_module == 1">
                     <div class="your-order card-header" id="headingTwo">
                       <h2 class="section-heading mt-0">
                          <button class="btn btn-link collapsed" type="OrderReview" data-toggle="collapse" data-target="#OrderReview" aria-expanded="true" aria-controls="collapseOne"> Order Review 
                            <span class="icon">
                              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> </svg>
                            </span>
                          </button>
                        </h2>      
                     </div>
                      <div id="OrderReview" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample"> 
                        <div class="card-body">
                          <ul class="review-listing list-unstyled">
                            <li v-if="order_details.data.order_review.store_review == 0 && review_rating_module == 1">
                              <div class="media my-profile review-profile">
                                <div class="profile-pic mr-3" v-if="order_details.data.store.store_logo!=''">
                                  <img :src="order_details.data.store.store_logo" :alt="order_details.data.store.store_name">
                                </div>
                                <avatar class="profile-pic mr-3" v-else :fullname="order_details.data.store.store_name.split(' ')[0]" :size="65"></avatar>
                                <div class="media-body">
                                  <h5>Please rate {{order_details.data.store.store_name}}</h5>
                                  Your feedback is important for us and help us improve to serve you better!
                                  <star-rating :star-size="20" :show-rating="false" active-color="#f4ac33" inactive-color="#ededed" v-model="store_review.rating"></star-rating>
                                </div>
                              </div>
                              <div class="rewiew-box">
                                <form>
                                  <div class="form-group">
                                    <label for="store_review" class="sr-only"></label>
                                    <textarea class="form-control" id="store_review" v-model="store_review.review" rows="2" placeholder="Mention what you like / dislike about us!"></textarea>
                                  </div>
                                </form>
                              </div>
                             </li>
                             <li v-if="order_details.data.order_review.delivery_review == 0 && order_details.data.deliveryboy_id != null && order_details.data.deliveryboy_id != 0 && review_rating_module == 1">
                              <div class="media my-profile review-profile">
                                <div class="profile-pic mr-3">
                                  <img v-if="order_details.data.deliveryboy_picture != null" :src="order_details.data.deliveryboy_picture" :alt="order_details.data.deliveryboy_name" />
                                  <avatar v-else :fullname="order_details.data.deliveryboy_name" :size="65"></avatar>
                                </div>
                                <div class="media-body">
                                  <h5>Please rate delivery services</h5>
                                  Your feedback will boost his enthusiasm and help him improve where he lacks!
                                  <star-rating :star-size="20" :show-rating="false" active-color="#f4ac33" inactive-color="#ededed" v-model="delivery_review.rating"></star-rating>
                                </div>
                              </div>
                              <div class="rewiew-box">
                                <form>
                                  <div class="form-group">
                                    <textarea class="form-control" id="delivery_review" v-model="delivery_review.review" rows="2" placeholder="Add a compliment for him and make his day!"></textarea>
                                  </div>
                                </form>
                              </div>
                             </li>
                           </ul>
                          <h5 class="mb-3" v-if="order_details.data.order_review.review_items != null && review_rating_module == 1 && show_review_rating == 1"><b>Tell us how the items was!</b></h5>
                           <ul class="review-listing list-unstyled" v-if="order_details.data.order_review.review_items != null && review_rating_module == 1 && show_review_rating == 1">
                            <li v-for="(review_item,ind) in order_details.data.order_review.review_items" v-bind:key="'review_item'+ind">
                              <div class="media my-profile review-profile align-items-center">
                                <div class="profile-pic mr-3">
                                  <img :src="review_item.item_image" :alt="review_item.item_name">
                                </div>
                                <div class="media-body align-items-center">
                                  <h5 class="mb-1">{{review_item.item_name}}</h5>
                                  <div class="current-rating" v-if="show_review_rating == 1 && review_item.avg_rating!=null && review_item.avg_rating > show_rating_different_color_code" :style="{ backgroundColor:default_rating_bg_color , color: default_rating_color_code_text}">
                                    <div class="media">
                                      <span class="icon">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-star-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"> <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> </svg>
                                      </span>
                                      <span class="media-body">
                                        <p v-if="review_item.avg_rating != null">{{review_item.avg_rating}}</p>
                                      </span>
                                    </div>
                                  </div>
                                  
                                  <div class="current-rating" v-if="show_review_rating == 1 && review_item.avg_rating!=null && show_rating_different_color_code !=0 && review_item.avg_rating <=show_rating_different_color_code" :style="{ backgroundColor:alternet_bg_color , color: alternate_color_code_text}">
                                    <div class="media">
                                      <span class="icon">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-star-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"> <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> </svg>
                                      </span>
                                      <span class="media-body">
                                        <p v-if="review_item.avg_rating != null">{{review_item.avg_rating}}</p>
                                      </span>
                                    </div>
                                  </div>
                                  
                                  <div class="current-rating" v-if="show_review_rating == 1 && review_item.avg_rating == null && show_rating ==1" :style="{ backgroundColor:default_rating_bg_color , color: default_rating_color_code_text}">
                                    <div class="media">
                                      <span class="icon">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-star-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"> <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/> </svg>
                                      </span>
                                      <span class="media-body">
                                        <p>5.0</p>
                                      </span>
                                    </div>
                                  </div>
                                  <star-rating :star-size="20" v-if="item_review[ind] != undefined && item_review[ind] != null" :show-rating="false" active-color="#f4ac33" inactive-color="#ededed" v-model="item_review[ind].rating"></star-rating>
                                </div>
                              </div>
                              <div class="rewiew-box">
                                <form>
                                  <div class="form-group">
                                    <textarea class="form-control" id="item_review" rows="2" v-if="item_review[ind] != undefined && item_review[ind] != null" placeholder="Add review for the item!" v-model="item_review[ind].review"></textarea>
                                  </div>
                                </form>
                              </div>
                            </li>
                           </ul>
                           <div class="button row mx-0 justify-content-center mt-4">
                            <a href="javascript:void(0)" :style="colorObj" class="btn review-button-style hoverEffect" @click="submitReview(order_details.data.order_id)">Submit</a>
                           </div>
                        </div>
                      </div>
                  </div>
                  <div class="card">
                    <div class="your-order card-header" id="headingThree">
                        <h2 class="section-heading mt-0">
                            <button class="btn btn-link collapsed" type="Ordersummary" data-toggle="collapse" data-target="#Ordersummary" aria-expanded="true" aria-controls="collapseOne">Order Summary 
                            <span class="icon">
                              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> </svg>
                            </span>
                          </button>
                        </h2>
                    </div>
                      <div id="Ordersummary" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div class="card-body">
                        <div class="your-order" v-if="order_details.data!=''">
                          <h2 class="section-heading" v-if="order_details.data.items!=''">Your Order</h2>
                          <div class="order-box" v-if="order_details.data!=''">
                            <div class="profile-id" v-if="order_details.data.items!=''">
                              <div
                                v-for="(items,index) in order_details.data.items"
                                v-bind:key="'items'+index"
                              >
                                <div
                                  class="media"
                                  v-for="(fooditem, ind) in getAddedItems(items.item_variants)"
                                  v-bind:key="'fooditem'+ind"
                                >
                                  <div class="ordered-profile mr-3">
                                    <img :src="items.item_image" alt="apple">
                                  </div>
                                  <div class="media-body">
                                    <div class="row form-row">
                                      <div class="col-sm-6 order-description">
                                        <h5>{{items.item_name}}</h5>
                                        <span v-if="parseFloat(fooditem.unit_quantity) != '0'">
                                          {{ Number( fooditem.unit_quantity ).toFixed(1) }}
                                        </span>
                                        <span class="ml-1" v-if="fooditem.unit_name != null">
                                          {{ fooditem.unit_name }}
                                        </span>
                                        <span class="ml-2" v-if="fooditem.packaging_name != null">
                                          {{ fooditem.packaging_name }}
                                        </span>
                                        <br>
                                        <span class="w-100 mb-0" v-if="parseFloat(fooditem.min_qty) > 0 && parseFloat(fooditem.max_qty) > 0">
                                          (Min {{ fooditem.min_qty }} - Max {{ fooditem.max_qty }})
                                        </span>
                                        <span class="w-100 mb-0" v-if="parseFloat(fooditem.min_qty) > 0 && parseFloat(fooditem.max_qty) == 0">
                                          (Min {{ fooditem.min_qty }})
                                        </span>
                                        <span class="w-100 mb-0" v-if="parseFloat(fooditem.min_qty) == 0 && parseFloat(fooditem.max_qty) > 0">
                                          (Max {{ fooditem.max_qty }})
                                        </span>
                                        <h6 class="w-100 mb-2 text-black" v-if="items.combo_variants != null">
                                          <span v-for="(combo,ind) in items.combo_variants" v-bind:key="'combo'+ind">                                            
                                            {{combo.combo_variant_name}}
                                            <br>
                                            <span class="ml-4" v-if="parseFloat(combo.unit_quantity) != '0' || combo.unit_name != null || combo.packaging_name != null">
                                              <span v-if="parseFloat(combo.unit_quantity) != '0'">
                                                {{ Number( combo.unit_quantity ).toFixed(1) }}
                                              </span>
                                              <span v-if="combo.unit_name != null">
                                                {{ combo.unit_name }} 
                                              </span>
                                              <span v-if="combo.packaging_name != null">
                                                {{ combo.packaging_name }}
                                              </span>
                                              <br>
                                            </span>
                                      <div class="col-sm-12" v-bind:key="'combo_choices'+ind" :v-if="combo.choices != null && combo.choices.length > 0" v-for="(obj, ind) in combo.choices">
                                        <div>
                                           <span :style="obj.choice_prices.filter(x=>x.is_added ==1).length > 0?'':'display:none'" class="addOnChoices"> {{obj.variant_choice_name}} :</span> <span v-bind:key="ind" :v-if="obj.choice_prices != null && obj.choice_prices.length > 0" v-for="(cp, ind) in obj.choice_prices"><span :style="cp.is_added == 1 ? '':'display:none'" >{{cp.variant_sub_choice_name}}<span :style="obj.choice_prices.indexOf(cp) > 0 && obj.choice_prices.indexOf(cp) > (obj.choice_prices.length - 1)?'':'display:none'">,</span></span></span>
                                        </div>
                                      </div>
                                      <div class="col-sm-12" v-bind:key="'combo_addons'+ind" :v-if="combo.addons != null && combo.addons.length > 0" v-for="(obj, ind) in combo.addons">
                                        <div>
                                           <span :style="obj.addon_prices.filter(x=>x.is_added ==1).length > 0?'':'display:none'" class="addOnChoices"> {{obj.variant_addon_name}} :</span> <span v-bind:key="ind" :v-if="obj.addon_prices != null && obj.addon_prices.length > 0" v-for="(cp, ind) in obj.addon_prices"><span :style="cp.is_added == 1 ? '':'display:none'" >{{cp.variant_sub_addon_name}}<span :style="obj.addon_prices.indexOf(cp) > -1 && obj.addon_prices.indexOf(cp) < (obj.addon_prices.length - 1)?'':'display:none'">,</span></span></span>
                                        </div>
                                      </div>
                                          </span>
                                          <br>
                                        </h6>
                                      </div>
                                      <div class="col-sm-3 order-rate text-sm-center mt-3 mt-sm-0">
                                        <h5 class="per-price">
                                          {{fooditem.quantity.split(".")[0]}} &#215;
                                          <span v-html="priceFormatter(fooditem.discounted_price)"></span>
                                        </h5>
                                      </div>
                                      <div class="col-sm-3 order-rate mt-3 mt-sm-0">
                                        <div class="my-auto ml-sm-auto">
                                          <h5 class="price">
                                            <span v-html="priceFormatter(calPriceByQty(fooditem.discounted_price, fooditem.quantity))"></span>
                                          </h5>
                                        </div>
                                      </div>
                                      <div class="col-sm-12" v-bind:key="'item_choices'+ind" :v-if="items.item_variants != null && items.item_variants.length > 0" v-for="(obj, ind) in items.item_variants">
                                          <div v-bind:key="'item_addons'+ind" :v-if="obj.choices_addons != null && obj.choices_addons.length > 0" v-for="(addon, ind) in obj.choices_addons">
                                           <span class="addOnChoices"> {{addon}}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="payment-details" v-if="order_details.data!=''">
                          <span v-if="order_details.data.items!=''">
                            <h2 class="section-heading">Payment</h2>
                            <ul class="payment-details-wrap list-unstyled">
                              <li class="d-flex justify-content-between">
                                <h5>Item Total</h5>
                                <i class="fa fa-inr"></i>
                                <p>
                                  <span v-html="priceFormatter(totalAmount)"></span>
                                </p>
                              </li>
                              <li
                                class="d-flex justify-content-between"
                                v-if="
                                  order_details.data.delivery_charges != null &&
                                  order_details.data.delivery_charges != 0"
                              >
                                <h5>Delivery Charges</h5>
                                <p>
                                  <span v-html="priceFormatter(order_details.data.delivery_charges)"></span>
                                </p>
                              </li>
                                                <li
                    class="d-flex justify-content-between delivery-charge"
                    v-if="this.order_details.total_tax  != '' && (parseFloat(this.order_details.data.order_tax) + parseFloat(this.order_details.data.packaging_cost)).toFixed(2) != '0.00'"
                  >
                    <h5 class="mb-0">Taxes & Charges
                        <span class="tax-and-charges orderdetail-section"><b><img src="../../assets/images/svgs/infoicon.svg"/></b>
                        <span class="title-span" v-html="(parseFloat(this.order_details.data.packaging_cost).toFixed(2) != 0.00 ?'Store Packaging Cost : '+this.currency_symbol+''+parseFloat(this.order_details.data.packaging_cost).toFixed(2)+ '<br/>':'')+(parseFloat(this.order_details.data.order_tax).toFixed(2) != 0.00 ?`${store_tax} : `+this.currency_symbol+''+parseFloat(this.order_details.data.order_tax).toFixed(2):'')">
                        </span>
                      </span>
                     </h5> 

                    <p class="mb-0">
                      <span v-html="priceFormatter((parseFloat(this.order_details.data.order_tax) + parseFloat(this.order_details.data.packaging_cost)).toFixed(2))"></span>
                    </p>
                  </li>
                              <li
                                class="discount d-flex justify-content-between"
                                v-if="
                                  order_details.data.offer_code != '' && 
                                  parseFloat(order_details.data.offer_discount) > 0"
                              >
                                <h5>Promo - ({{order_details.data.offer_code}})</h5>
                                <p>
                                  -
                                  <span v-html="priceFormatter(order_details.data.offer_discount)"></span>
                                </p>
                              </li>
                              <li
                                class="discount d-flex justify-content-between"
                                v-if="
                                  order_details.data.discount != '' && 
                                  parseFloat(order_details.data.discount) > 0"
                              >
                                <h5>Discount</h5>
                                <p>
                                  -
                                  <span v-html="priceFormatter(order_details.data.discount)"></span>
                                </p>
                              </li>
                              
                            </ul>
                            <div class="total d-flex justify-content-between">
                              <h4>Order Amount</h4>
                              <p>
                                <span v-html="priceFormatter(order_details.data.order_amount)"></span>
                              </p>
                            </div>
                            <ul class="list-unstyled">
                              <li class="d-flex justify-content-between" v-if="order_details.data.pay_thru_wallet === 1">
                                <h5 class="paid_by_wallet">Paid By wallet</h5>
                                <p>
                                  -
                                  <span class="paid_by_wallet" v-html="priceFormatter(order_details.data.wallet_amount)"></span>
                                </p>
                              </li>
                              <li class="d-flex justify-content-between" v-if="order_details.data.pay_thru_wallet === 1">
                                <h5>Final Amount</h5>
                                <p>
                                  <span v-html="priceFormatter(parseFloat((order_details.data.order_amount) - (order_details.data.wallet_amount)).toFixed(2))"></span>
                                </p>
                              </li>
                            </ul>
                            </span>
                            </div>
                            <div v-if="order_details.data.offer_remarks != ''" class="total d-flex justify-content-between">
                              <span class="appliedofferremarks">
                                <span style="color:black">Free Item(s) :</span> {{order_details.data.offer_remarks}}
                              </span>
                            </div>
                         
                        <div class="prescription-details">
                          <h2 class="section-heading" v-if="(order_details.data.order_images != null && order_details.data.order_images.length > 0)">Prescription Images</h2>
                          <ul class="details-listing list-unstyled mt-md-1 mb-md-0">
                          <section class="advertising py-0" v-if="order_details.data.order_images != null && order_details.data.order_images.length > 0">
                            <b-carousel
                              id="carousel-1"
                              :interval="5000"
                              controls
                              indicators
                            >
                              <b-carousel-slide v-for="(orderimg,index) in order_details.data.order_images" v-bind:key="'orderimg'+index">
                                <template #img>
                                  <img
                                    class="d-block img-fluid w-100"
                                    :src="orderimg"
                                    @click="orderImageRedirect(orderimg)"
                                    v-bind:style="orderImageBgImage()"
                                  >
                                </template>
                              </b-carousel-slide>
                            </b-carousel>
                            </section>
                          </ul>
                        </div>

                        <div class="order-details">
                          <h2 class="section-heading mb-4">Order Details</h2>
                          <ul class="details-listing list-unstyled mt-md-1 mb-md-0">

                            <!-- Remark -->
                            <li v-if="order_details.data.remarks!=''">
                                <h5>Remark</h5>  
                                <h6 class="mb-0">{{order_details.data.remarks}}</h6>
                            </li>

                            <!-- Order Number -->
                            <li>
                              <h5>Order Number</h5>
                              <h6 class="mb-0">{{order_details.data.order_no}}</h6>
                            </li>

                            <li>
                              <h5>Payment</h5>  
                              <h6 class="mb-0"><span v-if="order_details.data.payment_method_name != null && order_details.data.payment_method_name != ''">{{order_details.data.payment_method_name}}<span v-if="order_details.data.pay_thru_wallet === 1">,</span></span><span v-if="order_details.data.pay_thru_wallet === 1">{{ pay_through_wallet }}</span></h6>
                            </li>

                            <!-- Payment with Pay through wallet method plus activate payment method -->
                            <!-- <li v-if="order_details.data.payment_mode == 0 && order_details.data.pay_thru_wallet === 1">
                              <h5>Payment</h5>  
                              <h6 class="mb-0">{{ pay_through_wallet }}</h6>
                            </li> -->
                            
                            <!-- Pay through wallet only  -->
                            <!-- <li v-else-if="order_details.data.payment_mode == 1 && order_details.data.pay_thru_wallet === 1">
                              <h5>Payment</h5>  
                              <h6 class="mb-0">{{order_details.data.payment_method_name}}<span>,{{ pay_through_wallet }}</span></h6>
                            </li> -->

                            <!-- Payment with single payment method -->
                            <!-- <li v-else>
                              <h5>Payment</h5>  
                              <h6 class="mb-0">{{order_details.data.payment_method_name}}</h6>
                            </li> -->

                            <!-- Order Date - Time -->
                            <li>
                              <h5>Order Date - Time</h5>
                              <h6 class="mb-0">
                                {{order_details.data.order_date}}
                              </h6>
                            </li>

                            <!-- Delivery Time -->
                            <li v-if="order_details.data.delivery_type == 1 && order_details.data.delivery_time_type == 0 && order_details.data.delivery_date=='' && order_details.data.from_min != null">
                              <h5>Delivery Time</h5>
                              <h6 class="mb-0">+{{order_details.data.from_min}}{{order_details.data.to_min != "0"?" - ":""}}{{order_details.data.to_min != "0"?order_details.data.to_min:""}} {{ order_details.data.delivery_duration==1?"min(s)": order_details.data.delivery_duration==2?"hr(s)":"day(s)"}}</h6>
                            </li>

                            <!-- Pickup Time -->
                            <li v-if="order_details.data.delivery_type == 2 && order_details.data.pickup_time_type == 0 && order_details.data.delivery_date=='' && order_details.data.pickup_from != null">
                              <h5 v-if="order_details.data.iseatery == 0">Pickup Time</h5>
                              <h5 v-else>Take Away Time</h5>                              
                              <h6 class="mb-0">+{{order_details.data.pickup_from}}{{order_details.data.pickup_to != "0"?" - ":""}}{{order_details.data.pickup_to != "0"?order_details.data.pickup_to:""}} {{ order_details.data.pickup_duration==1?"min(s)": order_details.data.pickup_duration==2?"hr(s)":"day(s)"}}</h6>
                            </li>                            

                            <!-- Delivery Slot -->
                            <li v-if="order_details.data.delivery_type == 1 && order_details.data.delivery_time_type == 1 && order_details.data.delivery_slot != null && order_details.data.delivery_slot.slot != '' && order_details.data.delivery_slot.to_date != '' && order_details.data.order_status != '5'" >
                              <h5>Delivery Slot</h5>
                              <h6 class="mb-0">{{order_details.data.delivery_slot.slot}}</h6>
                            </li>

                            <!-- Pickup Slot -->
                            <li v-if="order_details.data.delivery_type == 2 && order_details.data.pickup_time_type == 1 && order_details.data.pickup_slot != null && order_details.data.pickup_slot.slot != '' && order_details.data.pickup_slot.to_date != ''">
                              <h5 v-if="order_details.data.iseatery == 0">Pickup Slot</h5>
                              <h5 v-else>Take Away Slot</h5>
                              <h6 class="mb-0">{{ order_details.data.pickup_slot.slot }}</h6>
                            </li>

                            <!-- Delivery/Pickup Date - Time -->
                            <li v-if="order_details.data.delivery_date!=''">
                              <h5>
                                <span v-if="order_details.data.delivery_type == 1">Delivery </span>
                                <span v-if="order_details.data.delivery_type == 2 && order_details.data.iseatery == 0">Pickup </span> <span v-if="order_details.data.delivery_type == 2 && order_details.data.iseatery == 1">Take Away </span> Date - Time
                              </h5>
                              <h6 class="mb-0">
                                {{order_details.data.delivery_date}}
                              </h6>
                            </li>

                            <!-- Scheduled Delivery -->
                            <li v-if="order_details.data.delivery_type == 1 && order_details.data.scheduled_delivery_date != '' && order_details.data.order_status != '5'">
                              <h5>Schedule your Delivery</h5>
                              <h6 class="mb-0">{{order_details.data.scheduled_delivery_date}}</h6>
                            </li>

                            <!-- Scheduled Pickup -->
                            <li v-if="order_details.data.delivery_type == 2 && order_details.data.scheduled_delivery_date != '' && order_details.data.order_status != '5'">
                              <h5 v-if="order_details.data.iseatery == 0">Schedule your Pickup</h5>
                              <h5 v-else>Schedule your Take Away</h5>
                              <h6 class="mb-0">{{order_details.data.scheduled_delivery_date}}</h6>
                            </li>

                            <!-- Revised Delivery Date - Time -->
                            <li v-if="order_details.data.delivery_type == 1 && order_details.data.revised_delivery_date != '' && order_details.data.order_status != '5'">
                              <h5>Revised Delivery Date - Time</h5>
                              <h6 class="mb-0">{{order_details.data.revised_delivery_date}}</h6>
                            </li>

                            <!-- Revised Delivery Slot -->
                            <li v-if="order_details.data.delivery_type == 1 && order_details.data.delivery_time_type == 1 && order_details.data.revised_delivery_slot != null && order_details.data.order_status != '5'">
                              <h5>Revised Delivery Slot</h5>
                              <h6 class="mb-0">{{ order_details.data.revised_delivery_slot.slot }}</h6>
                            </li>

                            <!-- Revised Pickup Date - Time -->
                            <li v-if="order_details.data.delivery_type == 2 && order_details.data.revised_delivery_date != '' && order_details.data.order_status != '5'">
                              <h5>Revised Pickup Date - Time</h5>
                              <h6 class="mb-0">{{order_details.data.revised_delivery_date}}</h6>
                            </li>

                            <!-- Revised Pickup Slot -->
                            <li v-if="order_details.data.delivery_type == 2 && order_details.data.pickup_time_type == 1 && order_details.data.revised_pickup_slot != null && order_details.data.order_status != '5'">
                              <h5 v-if="order_details.data.iseatery == 0">Revised Pickup Slot</h5>
                              <h5 v-if="order_details.data.iseatery == 1">Revised Take Away Slot</h5>
                              <h6 class="mb-0">{{order_details.data.revised_pickup_slot.slot}}</h6>
                            </li>                            

                            <!-- Pickup Code -->
                            <li v-if="order_details.data.delivery_type == 2 && order_details.data.pickup_code != null && order_details.data.order_status != '5'">
                              <h5 v-if="order_details.data.iseatery == 0">Pickup Code</h5>
                              <h5 v-if="order_details.data.iseatery == 1">Take Away Code</h5>
                              <h6 class="mb-0">{{order_details.data.pickup_code}}</h6>
                            </li>

                            <!-- Cancellation Number -->
                            <li v-if="order_details.data.cancellation_no != null">
                              <h5>Cancellation Number</h5>
                              <h6 class="mb-0">{{order_details.data.cancellation_no}}</h6>
                            </li>

                            <!-- Cancellation Date -->
                            <li v-if="order_details.data.cancellation_date != null">
                              <h5>Cancellation Date</h5>
                              <h6 class="mb-0">{{order_details.data.cancellation_date}}</h6>
                            </li>

                            <!-- Reject Reason -->
                            <li v-if="order_details.data.reject_reason != null && order_details.data.order_status != 4 && order_details.data.order_status != 5">
                              <h5 v-if="order_details.data.order_status == 6">Reject reason</h5>
                              <h5 v-if="order_details.data.order_status == 7">Undelivered reason</h5>
                              <h5 v-if="order_details.data.order_status == 8">Cancel reason</h5>
                              <h6 class="mb-0">{{order_details.data.reject_reason}}</h6>
                            </li>

                            <!-- Remark For Customer -->
                            <li v-if="order_details.data.remarks_for_customer != null">
                              <h5>Remark For Customer</h5>
                              <h6 class="mb-0">{{order_details.data.remarks_for_customer}}</h6>
                            </li>

                            <!-- Delivery/Pickup Address -->
                            <li>
                              <h5>
                                <span v-if="order_details.data.delivery_type == 1">Delivery </span> 
                                <span v-if="order_details.data.delivery_type == 2 && order_details.data.iseatery == 0">Pickup </span> <span v-if="order_details.data.delivery_type == 2 && order_details.data.iseatery == 1">Take Away </span> Address
                              </h5>
                              <a class="location" href="javascript:void(0);">
                                <img src="../../assets/images/location-icon-gray.png" alt="location-icon.png">
                                {{order_details.data.order_address}}
                              </a>
                            </li>
                            <!-- Order Refund Summary -->
                            
                          </ul>
                          <div id="order-refund-details-popup" v-if="order_details.data!=undefined && order_details.data.refund!=null" style="display:none">
                              <!-- <h3>Refund Details</h3> -->
                              <div class="table-responsive">
                              <ul class="list-unstyled list-refund-details">
                                  <li class="d-flex flex-nowrap">
                                    <span class="col-3 border"><b>Refund #</b></span>
                                    <span class="col-3 border"><b>Refund Date</b></span>
                                    <span class="col-2 border"><b>Amount</b></span>
                                    <span class="col-4 border"><b>Refund Mode</b></span>
                                  </li>
                                  <li v-for="(refund,index) in order_details.data.refund.refund_details" :key="'refund'+index" class="d-flex flex-nowrap ">
                                    <span class="col-3 border">{{refund.refund_no}}</span>
                                    <span class="col-3 border">{{refund.refund_date}}</span>
                                    <span class="col-2 border" v-html="priceFormatter(refund.refund_amount)"></span>
                                    <span class="col-4 border">{{refund.refund_thru_name}}</span>
                                  </li>
                              </ul>
                              </div>
                          </div>
                        </div>
                        <div class="button row mx-0 justify-content-center">
                          <span class="reorder" v-if="order_details.data.order_status==5 || order_details.data.order_status==6">
                          <a
                            href="javascript:void(0)"
                            :style="colorObj"
                            class="btn button-style re-ordered mr-4 hoverEffect"
                            @click="getReorder(order_details.data.store.store_id,order_details.data.order_id)"
                            v-if="order_details.data.order_status!=7 && order_details.data.is_subscription_expire == 0"
                          >Re-Order Now</a>
                          </span>
                          <span>
                            <a
                              href="javascript:void(0)"
                              class="btn button-style re-ordered hoverEffect"
                              data-dismiss="modal" aria-label="Close"
                              :style="colorObj"
                            >Close</a>
                          </span>
                        </div>

                        
                      </div>
                    </div>
                  </div>
                  <div class="card" v-if="order_details.data.refund != null">
                    <div class="your-order card-header" id="headingFour">
                        <h2 class="section-heading mt-0">
                          <button class="btn btn-link collapsed" type="Refundsummary" data-toggle="collapse" data-target="#Refundsummary" aria-expanded="true" aria-controls="collapseFour">Refund Summary<h6 class="mb-0" style="font-size:12px;margin-top:4px;margin-left:5px;"> (Total amount refunded {{ this.currency_symbol }}{{ order_details.data.refund.refund_amount}})</h6>
                            <span class="icon">
                              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> </svg>
                            </span>
                          </button>
                        </h2>
                    </div>
                    <div id="Refundsummary" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                      <div class="card-body">
                        <div class="table-responsive">
                          <ul class="list-unstyled list-refund-details">
                            <li class="d-flex flex-nowrap">
                              <span class="col-3 border"><b><h3>Refund #</h3></b></span>
                              <span class="col-3 border"><b><h3>Refund Date</h3></b></span>
                              <span class="col-2 border"><b><h3>Amount</h3></b></span>
                              <span class="col-4 border"><b><h3>Refund Mode</h3></b></span>
                            </li>
                            <li  v-for="(refund,index) in order_details.data.refund.refund_details" :key="'r'+index" class="d-flex flex-nowrap refund-detail-list">
                              <span class="col-3 border"><span>{{refund.refund_no}}</span></span>
                              <span class="col-3 border"><span>{{refund.refund_date}}</span></span>
                              <span class="col-2 border" v-html="priceFormatter(refund.refund_amount)"></span>
                              <span class="col-4 border"><span>{{refund.refund_thru_name}}</span></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <span v-if="order_details!=''">
                  <span v-if="order_details.data.order_id!=orderid">
                    <div class="order-box">
                      <div class="profile-id">
                        <p class="text-center">
                          <img src="../../assets/images/loading.gif">
                        </p>
                      </div>
                    </div>
                  </span>
                </span>
              </div>
            </span>
            <span v-if="order_details==''">
              <div class="order-box">
                <div class="profile-id">
                  <p class="text-center">
                  </p>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>
<script>
import { bus } from "../../main";
import moment from "moment-timezone";
import Avatar from "vue-avatar-component";
import firebase from 'firebase/app';
import helper from '../../store/helper';
import localconfig from "../../global_settings.json"
import StarRating from 'vue-star-rating'

export default {
  name: "OrderDetail",
  props: {
    orderid: {
      type: Number
    }
  },
  components: {
    Avatar,
    StarRating
  },
  mixins:[helper],
  data() {
    return {
      currency_symbol: "₹",
      userprofile: null,
      order_details: [],
      store_map_link: '',
      customer_reorder: {
        store_id: "",
        order_id: "",
        user_id: "",
        cart_type: "1",
        remove_cart: "0"
      },
      category: {
        shop_category_id: "",
        shop_category_name: "",
        type: "",
      },
      store_review: {
        rating: 0,
        review: '',
      },
      delivery_review: {
        rating: 0,
        review: '',
      },
      item_review: [],
      customer_review: {
        user_id: '',
        order_id: '',
        store_review: '',
        delivery_review: '',
        item_review: [],
        tmp_item_review: [],
      },
      order_refund_detail:{
        order_id: ''
      },
      delivery_message: [],
      pay_through_wallet : "Wallet",
      delivery_boy:'',
      expandcollapseclass:'collapse-down-arrow order-detail-downarrow',
      is_chat_active: 0,
      markers:[],
      from_markers:[],
      customerappshorturl:'',
      latitude:23.0225, 
      longitude:72.5714,
      livetrackingenable:true,
      path:[],
      zoomproperty:0,
      review_rating_module : 1,
      show_review_rating : 1,
      use_google_map : 1,
      default_rating_bg_color:"",
      default_rating_color_code_text:"",
      show_rating_different_color_code: 1,
      alternet_bg_color:"",
      alternate_color_code_text:"",
      show_rating:1,
      show_price_same_font:1
    };
  },
  computed: {
    totalPrice() {
      let sum = 0;
      if (this.order_details != "") {
        if (this.order_details.data != "") {
          if (this.order_details.data.items != "") {
            this.order_details.data.items.forEach(function(newitem) {
              sum +=
                parseFloat(newitem.item_variants[0].discounted_price) *
                parseFloat(newitem.item_variants[0].quantity);
            });
          }
        }
      }
      return sum;
    },
    totalAmount: function() {
      var sum = 0;
      if (this.order_details != null) {
        if (this.order_details.status != 0) {
          this.order_details.data.items.forEach(items => {
            var item_variants = items.item_variants.filter(
              function(elem) {
                if (elem.is_added == "1") return elem;
              }
            );
            item_variants.forEach(item_variant => {
              sum += parseFloat(item_variant.discounted_price) * parseFloat(item_variant.quantity);
            });
          });
        }
      }
      return parseFloat(sum).toFixed(2);
    },
  },
  filters: {
    formatDate: function(date) {
      var dateformat = (localStorage.getItem("date_format")!=null) ? localStorage.getItem("date_format") : localconfig.date_format_web;
      var utc_date = moment.utc(date, 'DD-MM-YYYY HH:mm:ss');
      if(dateformat == 'MM-DD-YYYY HH:mm:ss'){
        return moment(utc_date).format('MM-DD-YYYY HH:mm:ss');
      }
      if(dateformat == 'DD-MM-YYYY HH:mm:ss'){
        return moment(utc_date).format('DD-MM-YYYY HH:mm:ss');
      }  
    },
    formatDDate: function(date) {
      var utc_date = moment.utc(date, 'DD-MM-YYYY HH:mm:ss');
      return moment(utc_date).format('DD MMM YYYY h:mm A');
    },
    formatDeliveryDate: function(date){
      var dateformat = (localStorage.getItem("date_format")!=null) ? localStorage.getItem("date_format") : localconfig.date_format_web;
      var utc_date = moment.utc(date, 'DD-MM-YYYY HH:mm:ss');
      if(dateformat == 'MM-DD-YYYY HH:mm:ss'){
        return moment(utc_date).format('MM-DD-YYYY HH:mm');
      }
      if(dateformat == 'DD-MM-YYYY HH:mm:ss'){
        return moment(utc_date).format('DD-MM-YYYY HH:mm');
      }  
    },
    formatTime: function(date) {
      var utc_date = moment.utc(date, 'DD-MM-YYYY HH:mm:ss');
      return moment(utc_date).format('HH:mm');
    },
  },
  mounted() {
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
    this.livetrackingenable = localStorage.getItem("is_livetracking");
    this.customerappshorturl = localStorage.getItem("customer_app_short_url");

    this.currency_symbol = localStorage.getItem("currency_symbol") != null && localStorage.getItem("currency_symbol") != "null"?localStorage.getItem("currency_symbol"):"₹";
    bus.$on("ShowOrderDetails", data => {

      this.order_details = data;

      this.store_review.rating = 0;
      this.store_review.review = '';
      this.delivery_review.rating = 0;
      this.delivery_review.review = '';

      this.item_review = [];
      
      if (this.order_details != null) {
        if (this.order_details.status != 0 && this.order_details.data != null) {

          if (this.use_google_map == 1)
            this.store_map_link = 'https://www.google.com/maps/search/?api=1&query='+this.order_details.data.store.latitude+','+this.order_details.data.store.longitude;
          else
            this.store_map_link = 'https://www.google.com/maps/search/?api=1&query='+this.order_details.data.order_address;

          this.markers =[];
          this.path = [];
          this.from_markers = [];

          if(this.order_details.data.delivery_type == 2){
            this.getLocation();           
          }else{            
            this.path.push({ lat: parseFloat(this.order_details.data.from_latitude), lng: parseFloat(this.order_details.data.from_longitude) })  
          }

          let marker = { lat: parseFloat(this.order_details.data.to_latitude), lng: parseFloat(this.order_details.data.to_longitude) };
          if(this.order_details.data.delivery_type == 1){
            let from_marker = { lat: parseFloat(this.order_details.data.from_latitude), lng: parseFloat(this.order_details.data.from_longitude) };
            this.from_markers.push({ position: from_marker});
          }
          this.markers.push({ position: marker });
          this.path.push({ lat: parseFloat(this.order_details.data.to_latitude), lng: parseFloat(this.order_details.data.to_longitude) })
          if (this.order_details.data.from_latitude != null)
             this.latitude = parseFloat(this.order_details.data.from_latitude);
          else
             this.latitude = null;
          
          if (this.order_details.data.from_longitude != null)
             this.longitude = parseFloat(this.order_details.data.from_longitude);
          else
             this.longitude = null;
          this.center = marker;
          this.zoomproperty = (7 - (parseFloat(this.order_details.data.from_latitude) - parseFloat(this.order_details.data.to_latitude)));
          if (this.order_details.data.order_review != null && this.order_details.data.order_review.review_items != null) {
            
            this.order_details.data.order_review.review_items.forEach(e => {
              var obj = {};
              obj['order_item_id'] = e.order_item_id;
              obj['item_id'] = e.item_id;
              obj['item_variants_id'] = e.item_variants_id;
              obj['rating'] = 0;
              obj['review'] = "";
              this.item_review.push(obj);
            });
          }
        }
      }
      this.realTimeOrderUpdate()
    });
    this.delivery_boy = localStorage.getItem("delivery_boy");
    this.originalColor()
    this.is_chat_active = this.chat_module;
    this.review_rating_module = parseFloat(localStorage.getItem("review_rating_module"));
    this.show_review_rating = parseFloat(localStorage.getItem("show_review_rating"));
    this.use_google_map = parseFloat(localStorage.getItem("use_google_map"));
    this.default_rating_bg_color = localStorage.getItem("default_rating_bg_color");
    this.default_rating_color_code_text = localStorage.getItem("default_rating_text_color");
    this.show_rating_different_color_code = localStorage.getItem("show_rating_different_color_code");
    this.alternet_bg_color = localStorage.getItem("alternet_bg_color");
    this.alternate_color_code_text = localStorage.getItem("alternate_color_code_text");
    this.show_rating = parseFloat(localStorage.getItem("show_five_star_rating"));
    bus.$on("globalsetting",data => {
      if(Object.keys(data).length != 0){
        this.show_price_same_font = JSON.parse(data).show_price_same_font;
      }
    });
  },
  updated() {
    window.$(document).ready(function(){
      window.$("a.message-icons").css("background-color", localStorage.getItem("button_default_color"))
    })
    window.$('<style>.order-info .section-heading button:hover { color: '+localStorage.getItem("button_default_color")+'; }</style>').appendTo('body');
    window.$('.order-inquiry svg circle.st0').css({ fill: localStorage.getItem("button_default_color")});
    window.$('.order-inquiry svg g path.st1').css({ fill: localStorage.getItem("css_button_customer_default_font")});
  },
  methods: {
    contact(order_no,date){
      var obj = {};
      obj["order_no"] = order_no;
      obj["order_date"] = date;
      obj["from"] = "order-detail";
      obj["inquiry_type"] = 2;
      bus.$emit("showContactForm", obj);
    },
    async getLocation() {
			try {
        await this.$getLocation({
					enableHighAccuracy: false
				}).then(coordinates => {
          let marker = { lat: parseFloat(coordinates.lat), lng: parseFloat(coordinates.lng) };
          this.markers.push({ position: marker });
          this.path.push({ lat: parseFloat(coordinates.lat), lng: parseFloat(coordinates.lng) })
				});

			} catch (error) {
				console.log(error)
			}
		},
    bannerRedirect(ban){
      window.open(ban.pod_image, '_blank');
    },
    bannerBgImage(ban) {
      var result = '';
      result = 'background-image: url("' + ban.pod_image + '");';
      result += 'cursor:pointer;'
      return result;
    },
    orderImageRedirect(orderImage){
      window.open(orderImage, '_blank');
    },
    orderImageBgImage() {
      var result = '';
      result += 'cursor:pointer;'
      return result;
    },
    goToStorePage(store_id){
      window.$("#order-details-popup").modal("hide");
      this.$router.push({ path: `/shopdetail?storeid=${store_id}` });
    },
    getPaymentMethod(payment_method_id){
      let payment_method = '';
      switch (payment_method_id) {
        case 0:
          payment_method = 'Add to Khata';
        break;
        case 1:
          payment_method = 'Cash on Delivery';
        break;
        case 2:
          payment_method = 'Touchless Digital Payment@Delivery';
        break;
        case 3:
          payment_method = 'Payment Thru UPI';
        break;
        case 4:
          payment_method = 'Online Payment';
        break;
      }
      return payment_method;
    },
    priceFormatter(value) {
      if(value){
        var price = value.toString().split(".")[0];
        var decimal = value.toString().split(".")[1];
        var response = this.currency_symbol + price;
        var decimal_font_size = this.show_price_same_font;
        if(decimal != undefined){
          if(decimal_font_size == 1){
            response += "<span>." + decimal + "</span>";
          } else {
            response += "<span class='price-decimal'><small>." + decimal + "</small></span>";
          }
        } else {
          if(decimal_font_size == 1){
            response += "<span>.00</span>";
          } else{
            response += "<span class='price-decimal'><small>.00</small></span>";
          }
          
        }
        return response;
      } else {
        return this.currency_symbol + '0';
      }
    },
    calPriceByQty(price, qty){
      var total = (parseFloat(price) * parseFloat(qty))
      return total.toFixed(2);
    },
    getAddedItems(items){
      return items.filter(
        function(elem) {
          if (elem.is_added == "1")
          {return elem;}
        }
      );
    },
    getReorder(storeid, orderid) {
      this.customer_reorder.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.customer_reorder.store_id = storeid;
      this.customer_reorder.order_id = orderid;
      this.customer_reorder.cart_type = 1;
      this.customer_reorder.remove_cart = 0;
      this.$store
        .dispatch("getcustomrreordr", this.customer_reorder)
        .then(() => {
          window.$("#order-details-popup").modal("hide");
          this.$router.push("/cart");
        });
    },
    getStoreByCategory(category){
      if(localStorage.getItem("is_business_model")== 3){
        return true;
      } else {
        window.$("#order-details-popup").modal("hide");
        this.category.shop_category_id = category.category_id;
        this.category.shop_category_name = category.category_name;
        this.category.type = "category";
        localStorage.setItem("selected-category", JSON.stringify(this.category));
        this.$router.push({ path: `/category?categoryid=${this.category.shop_category_id.toString()}` });
      }
    },
        submitReview(orderid){
      if(this.store_review.rating == 0 && this.store_review.review != ""){
        this.$toast.error("Please enter the rating for store");
        return false;
      }

      if(this.delivery_review.rating == 0 && this.delivery_review.review != ""){
        this.$toast.error(`"Please enter the rating for ${this.delivery_boy}"`);
        return false;
      }

      var item_flag = true;
      this.item_review.forEach(function(item) {
        if(item.rating == 0 && item.review != ""){
          item_flag = false;
          return false;
        }
      });

      this.customer_review.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.customer_review.order_id = orderid;
      this.customer_review.store_review = (this.store_review.rating != 0) ? JSON.stringify(this.store_review) : null;
      this.customer_review.delivery_review = (this.delivery_review.rating != 0) ? JSON.stringify(this.delivery_review) : null;
      this.tmp_item_review = this.item_review;
      this.tmp_item_review = this.tmp_item_review.filter(
        function(elem) {
          if (elem.rating != 0 || elem.review != "") return elem;
        }
      );
      this.customer_review.item_review = (this.tmp_item_review.length > 0) ? JSON.stringify(this.tmp_item_review) : null;

      if(this.customer_review.store_review == null && this.customer_review.item_review == null){
        this.$toast.error("Please enter the rating for store");
        this.$toast.error("Please enter the rating for item");
        return false;
      }

      if(item_flag == false){
        this.$toast.error("Please enter the rating for item");
        return false; 
      }

      this.$store
        .dispatch("customeraddreview", this.customer_review)
        .then(response => {
          if(response.status == 1){
            this.$toast.success(response.message);
            window.$("#order-details-popup").modal("hide");
          } else {
            this.$toast.error(response.message);
          }
        });
    },
    realTimeOrderUpdate(){
      var _this = this;
      firebase.firestore().collection("StatusOrderCollection").onSnapshot((snap) => {
        let updatedTrackingStatus = []
        snap.forEach(doc => {
          updatedTrackingStatus.push(JSON.parse(doc.data().order_details))
        });

        
        for(var i=0; i<updatedTrackingStatus.length; i++){
          var order_no = (updatedTrackingStatus[i] != null && updatedTrackingStatus[i] != undefined) ? updatedTrackingStatus[i].order_no : 0;
          if(order_no == _this.order_details.data.order_no){
            if(updatedTrackingStatus != null && updatedTrackingStatus != undefined && _this.order_details != null && _this.order_details != undefined)
            {
            console.log(updatedTrackingStatus)
            if(updatedTrackingStatus.filter(x=>x.order_details != null && x.order_details != undefined).map(x=>x.order_details.order_id).indexOf(_this.order_details.data != null && _this.order_details.data != undefined ? _this.order_details.data.order_id : 0) <= -1){
            // console.log(x.order_details)
              // if(updatedTrackingStatus[i] != undefined && updatedTrackingStatus[i].order_status === 1){
              //   if(_this.order_details.data != undefined){
              //     _this.order_details.data.tracking_details[1].title = updatedTrackingStatus[i].tracking_details[1].title;
              //     _this.order_details.data.tracking_details[1].description = updatedTrackingStatus[i].tracking_details[1].description;
              //     _this.order_details.data.tracking_details[1].datetime = updatedTrackingStatus[i].tracking_details[1].datetime;
              //     _this.order_details.data.order_status = updatedTrackingStatus[i].order_status;
              //     _this.order_details.data.tracking_details[1].status = 1;
              //   }
              // }


              if(updatedTrackingStatus[i] != undefined && updatedTrackingStatus[i].order_status === 9){
                if(_this.order_details.data != undefined){
                  _this.order_details.data.tracking_details[1].title = updatedTrackingStatus[i].tracking_details[1].title;
                  _this.order_details.data.tracking_details[1].description = updatedTrackingStatus[i].tracking_details[1].description;
                  _this.order_details.data.tracking_details[1].datetime = updatedTrackingStatus[i].tracking_details[1].datetime;
                  _this.order_details.data.order_status = updatedTrackingStatus[i].order_status;
                  _this.order_details.data.tracking_details[1].status = 1;
                }
              }

              if(updatedTrackingStatus[i] != undefined && updatedTrackingStatus[i].order_status === 2 || updatedTrackingStatus[i].order_status === 6){
                if(_this.order_details.data != undefined){
                  _this.order_details.data.tracking_details[2].title = updatedTrackingStatus[i].tracking_details[2].title;
                  _this.order_details.data.tracking_details[2].description = updatedTrackingStatus[i].tracking_details[2].description;
                  _this.order_details.data.tracking_details[2].datetime = updatedTrackingStatus[i].tracking_details[2].datetime;
                  _this.order_details.data.order_status = updatedTrackingStatus[i].order_status;
                  _this.order_details.data.tracking_details[2].status = 1;
                }
              }

              if(updatedTrackingStatus[i] != undefined && updatedTrackingStatus[i].order_status === 3){
                if(_this.order_details.data != undefined){
                  _this.order_details.data.tracking_details[3].title = updatedTrackingStatus[i].tracking_details[3].title;
                  _this.order_details.data.tracking_details[3].description = updatedTrackingStatus[i].tracking_details[3].description;
                  _this.order_details.data.tracking_details[3].datetime = updatedTrackingStatus[i].tracking_details[3].datetime;
                  _this.order_details.data.order_status = updatedTrackingStatus[i].order_status;
                  _this.order_details.data.tracking_details[3].status = 1;
                }
              }
              
              if(updatedTrackingStatus[i] != undefined && (updatedTrackingStatus[i].order_status === 4 || updatedTrackingStatus[i].order_status === 5 || (updatedTrackingStatus[i].order_status === 6 && updatedTrackingStatus[i].tracking_details[1].title === "Order Confirmed") || (updatedTrackingStatus[i].order_status === 7) || updatedTrackingStatus[i].order_status === 8)){
                if(_this.order_details.data != undefined){
                  _this.order_details.data.tracking_details[3].title = updatedTrackingStatus[i].tracking_details[3].title;
                  _this.order_details.data.tracking_details[3].description = updatedTrackingStatus[i].tracking_details[3].description;
                  _this.order_details.data.tracking_details[3].datetime = updatedTrackingStatus[i].tracking_details[3].datetime;
                  _this.order_details.data.order_status = updatedTrackingStatus[i].order_status;
                  _this.order_details.data.tracking_details[3].status = 1;
                  if(updatedTrackingStatus[i].tracking_details[3].title === "Out for Delivery"){
                    _this.order_details.data.deliveryboy_id = updatedTrackingStatus[i].deliveryboy_id;
                  }
                }
              }
              
              if(updatedTrackingStatus[i].tracking_details[3] != undefined && (updatedTrackingStatus[i].tracking_details[3].title === "Out for Delivery" || updatedTrackingStatus[i].tracking_details[3].title === "Ready for Pickup")){
                if(updatedTrackingStatus[i].order_status === 4 && updatedTrackingStatus[i].tracking_details[3].title === "Out for Delivery"){
                  _this.order_details.data.tracking_details[4].title = updatedTrackingStatus[i].tracking_details[4].title;
                  _this.order_details.data.tracking_details[4].description = updatedTrackingStatus[i].tracking_details[4].description;
                  _this.order_details.data.tracking_details[4].datetime = null;
                  _this.order_details.data.tracking_details[4].status = 0;
                }
                if(updatedTrackingStatus[i].order_status === 5 || updatedTrackingStatus[i].order_status === 6 || updatedTrackingStatus[i].order_status === 7){
                  if(_this.order_details.data != undefined){
                    _this.order_details.data.tracking_details[4].title = updatedTrackingStatus[i].tracking_details[4].title;
                    _this.order_details.data.tracking_details[4].description = updatedTrackingStatus[i].tracking_details[4].description;
                    _this.order_details.data.tracking_details[4].datetime = updatedTrackingStatus[i].tracking_details[4].datetime;
                    _this.order_details.data.order_status = updatedTrackingStatus[i].order_status;
                    _this.order_details.data.tracking_details[4].status = 1;
                  }
                }
              }
            }
            }
          }
        }
      })
    },
    getOrderRefundSummary(orderid){
      this.order_refund_detail.order_id = orderid
      this.$store
      .dispatch("getcustomerorderdetail", this.order_refund_detail)
      .then(response => {
        bus.$emit("ShowOrderRefundDetails", JSON.parse(JSON.stringify(response)));
        //window.$("#order-refund-details-popup").modal("show");
        var eyetoogle = document.querySelector('#order-refund-details-popup')
        if(eyetoogle.style.display === "block"){
          eyetoogle.style.display = "none"
          this.expandcollapseclass = 'collapse-down-arrow order-detail-downarrow';
        } else{
          eyetoogle.style.display = "block"
          this.expandcollapseclass = 'collapse-down-arrow order-detail-downarrow order-detail-isopen';
        }
      });
    },
    orderChat(order_id){
      window.$("#order-details-popup").modal("hide");
      this.$router.push({ path: `/chat?orderid=${order_id.toString()}` });
    }
  }
};
</script>
<style>
a.chat-icon-order-description{ position: absolute; right: 70px; top: 94px;}
a.chat-icons{ background-color: #F3AC33; padding: 5px; width: 40px; height: 40px; display: flex; align-items: center; justify-content: center; border-radius: 50%;}
</style>